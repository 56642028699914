import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  erc20ABI,
  useContractReads,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import factoryABI from "../../assets/abis/factory.json";
import { refresh } from "../../features/refresh-slice";
import NumberComponent from "../basics/number-component";
import Button from "../buttons/button";
import { HandleRevertMessage } from "../error-messages/error-messages";
import Modal from "../modal/modal";
import RewardTokenSymbol from "../reward-token/reward-token-symbol";
import TokenLogo from "../token-logo/token-logo";
import Wallet from "../wallet/wallet";
import "./add-rewards.scss";

const MAX_INT =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

function calculateRewardTokenAmount(amount, decimals) {
  const amountCalculated =
    parseFloat((amount || 0).toString()) / 10 ** decimals;
  return amountCalculated;
}

const AddRewards = ({
  showModal,
  rewardToken,
  userAddress,
  poolAddress, // si es undefined hará el callback
  //used by create-reward-pool-and-add-rewards
  handleAddRewardsCallback,
  rewardsAmountDefaultRaw = "",
  rewardsAmountDefault = "",
  numOfEpochDefault = "",
  setFormValidatedRewards,
}) => {
  const [rewardsAmount, setRewardsAmount] = useState(rewardsAmountDefault); //
  const [rewardsAmountRaw, setRewardsAmountRaw] = useState(
    rewardsAmountDefaultRaw
  ); //
  const [numOfEpoch, setNumOfEpoch] = useState(numOfEpochDefault);
  const [modal, setModal] = useState(showModal);
  const [addRewardButtonClick, setAddRewardButtonClick] = useState(false);
  const [approveButtonClick, setApproveButtonClick] = useState(false);
  const [isOnMMProcess, setIsOnMMProcess] = useState(false);
  const [allDataEntered, setAllDataEntered] = useState(false);
  const [haveAllowance, setHaveAllowance] = useState(false);
  const [amountAllowanceBN, setAmountAllowanceBN] = useState(
    ethers.BigNumber.from("0")
  );
  const [amountAvailable, setAmountAvailable] = useState(0);

  const Toggle = () => setModal(!modal);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { all: rewardTokens } = useSelector((state) => state.rewardTokens);
  const dispatch = useDispatch();

  let decimals, symbol;
  let invalidRewardToken = false;
  if (
    typeof rewardToken === "string" &&
    rewardTokens[rewardToken.toLowerCase()] &&
    rewardTokens[rewardToken.toLowerCase()].decimals &&
    rewardTokens[rewardToken.toLowerCase()].symbol
  ) {
    decimals = rewardTokens[rewardToken.toLowerCase()].decimals;
    symbol = rewardTokens[rewardToken.toLowerCase()].symbol;
  } else {
    invalidRewardToken = true;
  }

  const rewardTokenContract = {
    address: rewardToken,
    abi: erc20ABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
  };

  const { data: tokenRewardData, isLoading: tokenRewardDataLoading } =
    useContractReads({
      contracts: [
        {
          ...rewardTokenContract,
          functionName: "allowance",
          args: [
            userAddress ? userAddress : ethers.constants.AddressZero,
            process.env.REACT_APP_FACTORY_ADDRESS,
          ],
        },
        {
          ...rewardTokenContract,
          functionName: "balanceOf",
          args: [userAddress ? userAddress : ethers.constants.AddressZero],
        },
      ],
      enabled: !invalidRewardToken,
      watch: true,
      onSuccess(data) {
        setAmountAllowanceBN(ethers.BigNumber.from(data[0]));
        setAmountAvailable(calculateRewardTokenAmount(data[1], decimals));
      },
    });

  const { config: approveConfig } = usePrepareContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    address: rewardToken,
    abi: erc20ABI,
    functionName: "approve",
    args: [process.env.REACT_APP_FACTORY_ADDRESS, MAX_INT],
    enabled:
      !invalidRewardToken &&
      rewardsAmount !== "" &&
      typeof rewardsAmount === "number" &&
      rewardsAmount > 0,
  });

  const {
    data: approveData,
    write: approveWrite,
    error: writeApproveError,
    isSuccess: approveIsSuccess,
    reset: approveReset,
    isError: approveIsError,
  } = useContractWrite({
    ...approveConfig,
  });

  const { isLoading: approveTxIsLoading, isSuccess: approveSuccess } =
    useWaitForTransaction({
      hash: approveData?.hash,
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      confirmations: 3,
    });

  const { config: addRewardsConfig } = usePrepareContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    address: process.env.REACT_APP_FACTORY_ADDRESS,
    abi: factoryABI,
    functionName: "addRewards",
    args: [
      poolAddress ? poolAddress : ethers.constants.AddressZero,
      rewardsAmount && rewardsAmount > 0
        ? ethers.utils.parseEther(rewardsAmount.toString()).toString()
        : "0",
      numOfEpoch,
    ],
    cacheTime: 0,
    enabled: haveAllowance && !!poolAddress && !invalidRewardToken,
  });

  const {
    data: addRewardsData,
    write: addRewardsWrite,
    error: writeAddRewardsError,
    reset: writeReset,
    isError: addRewardsIsError,
  } = useContractWrite({
    enabled: addRewardButtonClick && approveSuccess,
    ...addRewardsConfig,
  });

  const { isLoading: addRewardsTxIsLoading, isSuccess: addRewardsSuccess } =
    useWaitForTransaction({
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      hash: addRewardsData?.hash,
    });

  const handleClose = () => {
    try {
      writeReset();
    } catch (_) {}

    setRewardsAmount("");
    setRewardsAmountRaw("");
    setNumOfEpoch("");
    setAddRewardButtonClick(false);
    setApproveButtonClick(false);
    setIsOnMMProcess(false);
    setAllDataEntered(false);
    setHaveAllowance(false);
    setAmountAllowanceBN(ethers.BigNumber.from("0"));
    setAmountAvailable(0);

    if (
      handleAddRewardsCallback &&
      typeof handleAddRewardsCallback === "function"
    ) {
      handleAddRewardsCallback({
        rewardsAmountRaw: "",
        rewardsAmount: "",
        numOfEpoch: "",
      });
      setFormValidatedRewards(false);
    }
    Toggle();
  };

  const handleAmountChange = (e) => {
    setAddRewardButtonClick(false);
    let formattedRewardsAmount = "";
    if (e.target.value !== "") {
      setRewardsAmountRaw(e.target.value);
      const splitText = e.target.value.replace(/[^0-9.]/g, "").split(".");
      formattedRewardsAmount = splitText[0];
      if (splitText.length > 1) {
        formattedRewardsAmount += ".";
        if (splitText[1]) {
          formattedRewardsAmount += splitText[1].substring(decimals, 0);
        }
      }
    }
    setRewardsAmount(formattedRewardsAmount);
  };

  const handleNumOfEpochChange = (e) => {
    setNumOfEpoch(e.target.value.replace(/[^0-9]+/g, ""));
    setAddRewardButtonClick(false);
  };

  const handleAddRewardSubmitted = (e) => {
    e.preventDefault();

    let flag = true;
    const rewardsAmountFloat = parseFloat(rewardsAmountRaw || 0);

    if (
      numOfEpoch === "" ||
      rewardsAmountRaw === "" ||
      rewardsAmountRaw === "."
    ) {
      flag = false;
      NotificationManager.error(
        "Must be fill all the fields.",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && numOfEpoch <= 0) {
      flag = false;
      NotificationManager.error(
        "Num. of Epoch must be greater than 0.",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && numOfEpoch > 90) {
      flag = false;
      NotificationManager.error(
        "Can't send more than 90 epochs at the same time",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && rewardsAmountFloat <= 0) {
      flag = false;
      NotificationManager.error(
        "Rewards amount must be greater than 0.",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (
      flag &&
      amountAllowanceBN.lt(
        ethers.utils.parseEther(rewardsAmountFloat.toString() ?? "0")
      )
    ) {
      flag = false;
      NotificationManager.error(
        "Insufficient allowance. Must increase it with new approval.",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && parseFloat(amountAvailable) < rewardsAmountFloat) {
      flag = false;
      NotificationManager.error(
        "Amount entered is larger than your available tokens",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag) {
      setAddRewardButtonClick(true);
      setHaveAllowance(true);
    } else {
      setHaveAllowance(false);
      setAddRewardButtonClick(false);
    }
  };

  const handleApproveSubmitted = (e) => {
    e.preventDefault();
    setRewardsAmount(parseFloat(rewardsAmountRaw));

    let flag = true;

    if (!rewardsAmountRaw) {
      flag = false;
    }
    if (flag && !isNaN(rewardsAmountRaw)) {
      setApproveButtonClick(true);
    }
  };

  // use effects
  useEffect(() => {
    if (addRewardButtonClick) {
      if (poolAddress) {
        if (addRewardsWrite) {
          addRewardsWrite();
          setAddRewardButtonClick(false);
          setIsOnMMProcess(true);
        }
      } else if (
        handleAddRewardsCallback &&
        typeof handleAddRewardsCallback === "function"
      ) {
        handleAddRewardsCallback({
          rewardsAmountRaw,
          rewardsAmount:
            rewardsAmount && rewardsAmount > 0
              ? ethers.utils.parseEther(rewardsAmount.toString()).toString()
              : "0",
          numOfEpoch,
        });
      }
    }
  }, [addRewardButtonClick, addRewardsWrite]);

  useEffect(() => {
    if (approveWrite && approveButtonClick) {
      approveWrite();
      setApproveButtonClick(false);
      setIsOnMMProcess(true);
    }
  }, [approveButtonClick, approveWrite]);

  useEffect(() => {
    setAllDataEntered(rewardsAmount !== "" && numOfEpoch !== "");
  }, [rewardsAmount, numOfEpoch]);

  useEffect(() => {
    //conditional behaviors
    if (addRewardsSuccess && isOnMMProcess) {
      setAddRewardButtonClick(false);
      setIsOnMMProcess(false);
      setHaveAllowance(false);
      writeReset();
      NotificationManager.success(
        "Rewards added succesfully",
        "Done!",
        process.env.NOTIFICATION_TIME
      );

      Toggle();
      dispatch(refresh({ name: "epochRewards", value: true }));
    }
  }, [addRewardsSuccess, isOnMMProcess, writeReset]);

  if (invalidRewardToken) {
    NotificationManager.error(
      "Reward Token selected is not an ERC20 contract",
      "Error!",
      process.env.NOTIFICATION_TIME
    );
    Toggle();
    return;
  }
  if (addRewardsIsError && numOfEpoch && rewardsAmount) {
    setAddRewardButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    writeReset();
    HandleRevertMessage(writeAddRewardsError);
  }

  //approve
  if (approveIsSuccess && isOnMMProcess) {
    setApproveButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    setAmountAllowanceBN(ethers.BigNumber.from(MAX_INT));
  }

  if (approveIsError && rewardsAmount) {
    setApproveButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    approveReset();
    HandleRevertMessage(writeApproveError);
  }

  return (
    <Modal
      show={modal}
      close={handleClose}
      title="Add rewards to pool"
      textButton={"Cancel"}
      hideButton={addRewardsTxIsLoading || approveTxIsLoading || isOnMMProcess}
    >
      <div className="row">
        <div className="col-12 text-center mt-3">
          <div className="row">
            <div className="col-12">
              <h3>
                <TokenLogo
                  customClass="pool-detail-img-big"
                  address={rewardToken}
                  chainId={process.env.REACT_APP_CHAIN_ID}
                />
                <span className="white">
                  <RewardTokenSymbol symbol={symbol} address={rewardToken} />
                </span>
              </h3>
            </div>
          </div>
          {!tokenRewardDataLoading ? (
            <div className="row">
              <div className="col-12">
                <h6>
                  Available: <NumberComponent num={amountAvailable} />
                  {" " + symbol}
                </h6>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row mt-4">
            <div className="col-12">
              {!userAddress ? (
                <></>
              ) : chain.id.toString() !== process.env.REACT_APP_CHAIN_ID ? (
                <Button
                  text="Change to correct Network"
                  type="button-light"
                  onClick={() =>
                    switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
                  }
                />
              ) : isOnMMProcess ||
                approveTxIsLoading ||
                addRewardsTxIsLoading ? (
                <input
                  className="add-rewards-input-disabled"
                  type="text"
                  value={rewardsAmountRaw}
                  disabled={true}
                />
              ) : (
                <input
                  className="create-pool-input"
                  type="text"
                  value={rewardsAmountRaw}
                  placeholder="Amount"
                  maxLength="59"
                  onChange={handleAmountChange}
                />
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {!tokenRewardDataLoading &&
                rewardsAmount &&
                (calculateRewardTokenAmount(tokenRewardData[0], decimals) >=
                  parseFloat(rewardsAmount) ||
                  approveSuccess) &&
                (isOnMMProcess ||
                approveTxIsLoading ||
                addRewardsTxIsLoading ? (
                  <input
                    className="add-rewards-input-disabled"
                    type="text"
                    value={numOfEpoch}
                    disabled={true}
                  />
                ) : (
                  <input
                    className="create-pool-input"
                    type="text"
                    value={numOfEpoch}
                    placeholder="Num. of weeks"
                    maxLength="2"
                    onChange={handleNumOfEpochChange}
                  />
                ))}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {(() => {
                if (
                  userAddress &&
                  chain.id.toString() === process.env.REACT_APP_CHAIN_ID
                ) {
                  if (!tokenRewardDataLoading) {
                    if (amountAvailable < parseFloat(rewardsAmountRaw ?? 0)) {
                      return (
                        <Button
                          text="Not enough funds"
                          type="button-disabled"
                        />
                      );
                    } else if (
                      parseInt(tokenRewardData[0].toString()) / 10 ** decimals <
                        rewardsAmount &&
                      !approveSuccess
                    ) {
                      if (approveTxIsLoading) {
                        return (
                          <Button text="Approving…" type="button-disabled" />
                        );
                      } else if (isOnMMProcess) {
                        return (
                          <Button
                            text="Confirm the action on your wallet…"
                            type="button-disabled"
                          />
                        );
                      } else {
                        return (
                          <Button
                            id="button-approve"
                            onClick={handleApproveSubmitted}
                            text={`Approve ${symbol}`}
                            type="button-light"
                          />
                        );
                      }
                    } else {
                      if (addRewardsTxIsLoading) {
                        return (
                          <Button
                            text="Adding rewards…"
                            type="button-disabled"
                          />
                        );
                      } else if (isOnMMProcess) {
                        return (
                          <Button
                            text="Confirm the action on your wallet…"
                            type="button-disabled"
                          />
                        );
                      } else if (allDataEntered) {
                        return (
                          <Button
                            id="button-add-rewards"
                            onClick={handleAddRewardSubmitted}
                            text="Add Rewards"
                            type="button-light"
                          />
                        );
                      } else {
                        return <></>;
                      }
                    }
                  } else {
                    return <Button text="Loading…" type="button-disabled" />;
                  }
                } else {
                  return <Wallet />;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRewards;
