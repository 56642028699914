import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAccount, useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { useInvalidNetwork } from "../../services/use-invalid-network";
import ApiKeyBox from "../api-key-box/api-key-box";
import DataBox from "../data-box/data-box";
import DataCertifiedBox from "../data-certified-box/data-certified-box";
import InfoCertifyBox from "../info-certify-box/info-certify-box";

const now = Date.now();

const CertifyTrades = ({}) => {
  const { poolAddress } = useParams();

  const [searchParams] = useSearchParams();

  const root = document.documentElement;
  // ToDo: buttons
  if (searchParams.get('btnBg')) {
    root?.style.setProperty(
      "--btn_bg",
      '#'+searchParams.get('btnBg'),
    );
    root?.style.setProperty(
      "--second_btn_bg",
      '#'+searchParams.get('btnBg'),
    );
    root?.style.setProperty(
      "--second_btn_txt_color",
      '#fff',
    );
  }
  if (searchParams.get('btnFont')) {
    root?.style.setProperty(
      "--btn_font",
      searchParams.get('btnFont'),
    );
  }
  if (searchParams.get('btnRadius')) {
    root?.style.setProperty(
      "--btn_radius",
      searchParams.get('btnRadius'),
    );
  }
  if (searchParams.get('btnBorder')) {
    root?.style.setProperty(
      "--btn_border",
      '#'+searchParams.get('btnBorder'),
    );
    root?.style.setProperty(
      "--second_btn_border",
      '#'+searchParams.get('btnBorder'),
    );
  }
  if (searchParams.get('font')) {
    root?.style.setProperty(
      "--main_font",
      searchParams.get('font'),
    );
  }
  if (searchParams.get('bgColor')) {
    root?.style.setProperty(
      "--bg_color",
      '#'+searchParams.get('bgColor'),
    );
  }
  if (searchParams.get('txtColor')) {
    root?.style.setProperty(
      "--txt_color",
      '#'+searchParams.get('txtColor'),
    );
  }
  if (searchParams.get('secondBgColor')) {
    root?.style.setProperty(
      "--second_bg",
      '#'+searchParams.get('secondBgColor'),
    );
  }
  if (searchParams.get('mainColor')) {
    root?.style.setProperty(
      "--main_blue",
      '#'+searchParams.get('mainColor'),
    );
  }
  if (searchParams.get('secondaryColor')) {
    root?.style.setProperty(
      "--second_blue",
      '#'+searchParams.get('secondaryColor'),
    );
  }

  const [screen, setScreen] = useState("firstScreen");
  const [certifiedData, setCertifiedData] = useState({});
  const [keyRenderInfoCertify, setKeyRenderInfoCertify] = useState();
  const isValidAddress = ethers.utils.isAddress(poolAddress);
  const isInvalidNetwork = useInvalidNetwork();
  const { isConnected, address: userAddress } = useAccount();

  const lmPoolContract = {
    address: poolAddress,
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
    enabled: isValidAddress,
  };

  const { data, isError, isLoading } = useContractReads({
    scopeKey: 'zk-' + now,
    contracts: [
      {
        ...lmPoolContract,
        functionName: "pairTokenA",
      },
      {
        ...lmPoolContract,
        functionName: "pairTokenB",
      },
      {
        ...lmPoolContract,
        functionName: "getCurrentEpoch",
      },
      {
        ...lmPoolContract,
        functionName: "rewardToken",
      },
      {
        ...lmPoolContract,
        functionName: "chainId",
      },
      {
        ...lmPoolContract,
        functionName: "exchange",
      },
      {
        ...lmPoolContract,
        functionName: "getStartDate",
      },
      {
        ...lmPoolContract,
        functionName: "poolType",
      },
    ],
    // enabled: !isInvalidNetwork,
    watch: true,
  });

  let [
    addressTokenA,
    addressTokenB,
    currentEpoch,
    rewardToken,
    chainId,
    exchange,
    startDate,
    poolType,
  ] = data ? data  : [null, null, null, null, null, null, null, null];

  const { data: epochData } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        args: [data && Array.isArray(data) ? parseInt(data[2]) : 0],
        functionName: "getEpochEnd",
      },
      {
        ...lmPoolContract,
        args: [data && Array.isArray(data) ? parseInt(data[2]) : 0],
        functionName: "getRewardsPerEpoch",
      },
    ],
    enabled:
      !isInvalidNetwork &&
      typeof data === "object" &&
      Array.isArray(data) &&
      data.length > 0,
    watch: true,
    onSuccess(epochData) {
      const newKeyRenderInfoCertify =
        parseInt(data[2]) + "-" + epochData[0] + "-" + epochData[1];
      if (newKeyRenderInfoCertify !== keyRenderInfoCertify) {
        setKeyRenderInfoCertify(newKeyRenderInfoCertify);
        setScreen("firstScreen");
      }
    },
  });

  let [endDate, rewardsPerEpoch] = epochData ? epochData : [null, null];

  useEffect(() => {
    if (userAddress && isConnected) {
      window.parent.postMessage({success: true, address: userAddress, step: 'CONNECT'}, "*");
    }
  }, [userAddress, isConnected]);

  return (
    <>
      {!isLoading && !isError && screen === "firstScreen" && (
        <InfoCertifyBox
          setScreen={setScreen}
          isConnected={isConnected}
          rewardsPerEpoch={rewardsPerEpoch}
          key={keyRenderInfoCertify}
        />
      )}
      {!isLoading && !isError && screen === "secondScreen" && (
        <ApiKeyBox
          setScreen={setScreen}
          setCertifiedData={setCertifiedData}
          endDate={endDate}
          rewardsPerEpoch={rewardsPerEpoch}
          senderAddress={userAddress}
          isConnected={isConnected}
          exchangeName={exchange}
        />
      )}
      {!isLoading && !isError && screen === "thirdScreen" && (
        <DataBox
          setScreen={setScreen}
          certifiedData={certifiedData}
          addressTokenA={addressTokenA}
          addressTokenB={addressTokenB}
          chainId={chainId}
          isConnected={isConnected}
          userAddress={userAddress}
        />
      )}
      {!isLoading && !isError && screen === "fourthScreen" && (
        <DataCertifiedBox isConnected={isConnected} />
      )}
    </>
  );
};

export default CertifyTrades;
