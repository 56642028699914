import { memo } from "react";
import { NotificationManager } from "react-notifications";
import exchangeList from "../../assets/json/exchanges-list.json";
import ChainLogo from "../chain-logo/chain-logo";
import "./exchange-name.scss";

const ExchangeName = ({
  showExchangeName = true,
  addressTokenA,
  addressTokenB,
  chainId,
  exchangeName,
  height = 20,
  width = 20,
  noLink
}) => {
  let exchangeNames = [];
  const exchangeTitles = new Map();
  exchangeList.tags.map((el) => {
    exchangeNames.push(el.key);
    exchangeTitles.set(el.key, el.title);
  });


  if (!exchangeNames.includes(exchangeName)) {
    NotificationManager.error(
      "An error has occurred reading the smart contract",
      "Error!",
      process.env.NOTIFICATION_TIME
    );
    return;
  }

  if (!exchangeList.exchangeTypes.dex.includes(exchangeName)) {
    if (exchangeName === "bitget" || exchangeName === "bitget-futures") {
      const url = "https://bonus.bitget.com/zk";
      return (
        <div>
          <a
            href={url}
            rel="noreferrer"
            target="_blank"
            title="Trade"
            className="exchange-link"
          >
            <img
              className="cex-img"
              width={width}
              src={`/assets/img/exchanges/${exchangeName}.svg`}
              alt="logo exchange"
            />
            <span className="capitalize"> {exchangeTitles.get(exchangeName)}</span>
          </a>
        </div>
      );

    }
    return (
      <div>
        <img
          className="cex-img"
          width={width}
          src={`/assets/img/exchanges/${exchangeName}.svg`}
          alt="logo exchange"
        />
        <span className="capitalize"> {exchangeTitles.get(exchangeName)}</span>
      </div>
    );
  }

  let dexPrefix = "https://pancakeswap.finance/swap";

  let chainName = "mainnet";

  if (Number(chainId) === 137) {
    chainName = "polygon";
  } else if (Number(chainId) === 56) {
    chainName = "bsc";
  } else {
    chainName = chainId;
  }

  let url = dexPrefix + "?chain=" + chainName;

  if (addressTokenA && addressTokenB) {
    url =
      url +
      "&outputCurrency=" +
      addressTokenB +
      "&inputCurrency=" +
      addressTokenA;
  }

  if (exchangeName === "uniswap") {
    url =
      "/swap/uniswap/" + addressTokenA + "/" + addressTokenB + "/" + chainId;
  }

  const exchangeHtml = (
    <>
      <img
        className="cex-img"
        width={width}
        src={`/assets/img/exchanges/${exchangeName}.svg`}
        alt="logo exchange"
      />
      <ChainLogo height={height} chainId={chainId}></ChainLogo>
      {showExchangeName && (
        <span className="capitalize"> {exchangeTitles.get(exchangeName)}</span>
      )}
    </>
  );

  return <div>
    {(noLink 
      ? exchangeHtml
      : <a
          href={url}
          rel="noreferrer"
          target="_blank"
          title="Trade"
          className="exchange-link"
        >
          {exchangeHtml}
        </a>
    )}
    </div>
};

export default memo(ExchangeName);
