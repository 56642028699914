import { memo } from "react";
import "./footer.scss";
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  if (location.pathname.startsWith('/widget')) {
    return (<></>);
  }
  return (
    <footer>
      <div className="row pt-40 pb-20">
        <div className="col-lg-12 justify-center">
          <div className="logo-container">
            <a
              href="https://discord.com/invite/zkmakers-1039148404443455498"
              target="_blank"
              rel="noreferrer"
              className="social-logo w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/642b30bda4bca02cd9e74978/6433d7aed579309f99471303_discord-black-icon-1.png"
                loading="lazy"
                alt="Discord icon"
                className="image-discord"
              />
            </a>
          </div>
          <div className="logo-container">
            <a
              href="https://t.me/zkMakers"
              target="_blank"
              rel="noreferrer"
              className="social-logo w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/642b30bda4bca02cd9e74978/6433d7adac66a40c010247c0_tg%20icon%20dark.png"
                loading="lazy"
                alt="Telegram icon"
                className="image-telegram"
              />
            </a>
          </div>
          <div className="logo-container">
            <a
              href="https://twitter.com/zkMakers"
              target="_blank"
              rel="noreferrer"
              className="social-logo w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/642b30bda4bca02cd9e74978/642b30bda4bca0513fe749ea_twitter%201.svg"
                loading="lazy"
                alt="Twitter icon"
                className="image-twitter"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
