import {
    ConnectorNotFoundError,
    ResourceUnavailableError,
    UserRejectedRequestError,
    getClient,
  } from '@wagmi/core'
  import { getAddress } from 'ethers/lib/utils.js'
  
  import { InjectedConnector } from '@wagmi/connectors/injected'
  
  
  export class MetaMaskConnector extends InjectedConnector {
    id = 'metaMask'
  
    shimDisconnectKey = `${this.id}.shimDisconnect`
  
    #UNSTABLE_shimOnConnectSelectAccount
  
    constructor({
      chains,
      options: options_,
    } = {}) {
      const options = {
        name: 'MetaMask',
        shimDisconnect: true,
        async getProvider() {
          function getReady(ethereum) {
            const isMetaMask = !!ethereum?.isMetaMask
            if (!isMetaMask) return
            // Brave tries to make itself look like MetaMask
            // Could also try RPC `web3_clientVersion` if following is unreliable
            if (ethereum.isBraveWallet && !ethereum._events && !ethereum._state)
              return
            if (ethereum.isApexWallet) return
            if (ethereum.isAvalanche) return
            if (ethereum.isBitKeep) return
            if (ethereum.isBlockWallet) return
            if (ethereum.isKuCoinWallet) return
            if (ethereum.isMathWallet) return
            if (ethereum.isOkxWallet || ethereum.isOKExWallet) return
            if (ethereum.isOneInchIOSWallet || ethereum.isOneInchAndroidWallet)
              return
            if (ethereum.isOpera) return
            if (ethereum.isPortal) return
            if (ethereum.isRabby) return
            if (ethereum.isTokenPocket) return
            if (ethereum.isTokenary) return
            if (ethereum.isZerion) return
            return ethereum
          }
  
          if (typeof window === 'undefined') return
          // eslint-disable-next-line no-undef
          const ethereum = await detectEthereumProvider()
          return ethereum;
        },
        ...options_,
      }
      super({ chains, options })
  
      this.#UNSTABLE_shimOnConnectSelectAccount =
        options.UNSTABLE_shimOnConnectSelectAccount
    }
  
    async connect({ chainId } = {}) {
      try {
        console.log('Connect!');
        const provider = await this.getProvider()
        console.log(provider);
        if (!provider) throw new ConnectorNotFoundError()
  
        if (provider.on) {
          provider.on('accountsChanged', this.onAccountsChanged)
          provider.on('chainChanged', this.onChainChanged)
          provider.on('disconnect', this.onDisconnect)
        }
  
        this.emit('message', { type: 'connecting' })
  
        // Attempt to show wallet select prompt with `wallet_requestPermissions` when
        // `shimDisconnect` is active and account is in disconnected state (flag in storage)
        let account = null
        if (
          this.#UNSTABLE_shimOnConnectSelectAccount &&
          this.options?.shimDisconnect &&
          !getClient().storage?.getItem(this.shimDisconnectKey)
        ) {
          account = await this.getAccount().catch(() => null)
          const isConnected = !!account
          if (isConnected)
            // Attempt to show another prompt for selecting wallet if already connected
            try {
              await provider.request({
                method: 'wallet_requestPermissions',
                params: [{ eth_accounts: {} }],
              })
              // User may have selected a different account so we will need to revalidate here.
              account = await this.getAccount()
            } catch (error) {
              // Not all MetaMask injected providers support `wallet_requestPermissions` (e.g. MetaMask iOS).
              // Only bubble up error if user rejects request
              if (this.isUserRejectedRequestError(error))
                throw new UserRejectedRequestError(error)
              // Or MetaMask is already open
              if (
                error.code ===
                new ResourceUnavailableError(error).code
              )
                throw error
            }
        }
  
        if (!account) {
          const accounts = await provider.request({
            method: 'eth_requestAccounts',
          })
          account = getAddress(accounts[0])
        }
  
        // Switch to chain if provided
        let id = await this.getChainId()
        let unsupported = this.isChainUnsupported(id)
        if (chainId && id !== chainId) {
          const chain = await this.switchChain(chainId)
          id = chain.id
          unsupported = this.isChainUnsupported(id)
        }
  
        if (this.options?.shimDisconnect)
          getClient().storage?.setItem(this.shimDisconnectKey, true)
  
        return { account, chain: { id, unsupported }, provider }
      } catch (error) {
        if (this.isUserRejectedRequestError(error))
          throw new UserRejectedRequestError(error)
        if ((error).code === -32002)
          throw new ResourceUnavailableError(error)
        throw error
      }
    }
  }