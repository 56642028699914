import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { bsc, mainnet, polygon, polygonMumbai } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { store } from "./app/store";
import Admin from "./components/admin/admin/admin";
import CertifyTrades from "./components/certify-trades/certify-trades";
import CreateRewardPoolAndAddRewards from "./components/create-reward-pool/create-reward-pool-and-add-rewards";
import Faucet from "./components/faucet/faucet";
import Footer from "./components/footer/footer";
import Init from "./components/init/init";
import Navbar from "./components/navbar/navbar";
import Pool from "./components/pool-detail/pool-detail";
import RewardPools from "./components/reward-pools/reward-pools";
import Page404 from "./components/special-pages/page-404";
import UniswapSwap from "./components/uniswap-swap/uniswap-swap";
import "./index.scss";
import { MetaMaskConnector } from './services/metamask-connector';

const apolloCache = new InMemoryCache();
const graphqlClient = new ApolloClient({
  cache: apolloCache,
  uri: process.env.REACT_APP_SUBGRAPH_URI,
});

let mumbaiChain = { ...polygonMumbai };
mumbaiChain.rpcUrls.default.http[0] =
  process.env.REACT_APP_MUMBAI_RPC_URL_DEFAULT;
const mainnetChain = mainnet;
const polygonChain = polygon;
const bscChain = bsc;

const { chains, provider } = configureChains(
  [mumbaiChain, polygonChain, mainnetChain, bscChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (
          chain.id !== mainnetChain.id &&
          chain.id !== bscChain.id &&
          chain.id !== polygonChain.id &&
          chain.id !== mumbaiChain.id
        ) {
          return null;
        }
        return { http: chain.rpcUrls.default.http[0] };
      },
    }),
    infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY }),
  ]
);
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        showQrModal: true,
      },
    }),
  ],
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="container-fluid">
    <>
      <Provider store={store}>
        <ApolloProvider client={graphqlClient}>
          <WagmiConfig client={client}>
            <BrowserRouter>
              <Init />
              <Navbar />
              <Routes>
                <Route exact path="/" element={<RewardPools />} />
                <Route exact path="/faucet" element={<Faucet />} />
                <Route
                  exact
                  path="/widget/certify/:poolAddress"
                  element={<CertifyTrades />}
                />
                <Route exact path="/rewards" element={<Admin />} />
                <Route
                  exact
                  path="/createPool"
                  element={<CreateRewardPoolAndAddRewards />}
                />
                <Route exact path="/pool/:poolAddress" element={<Pool />} />
                <Route exact path="/swap/uniswap" element={<UniswapSwap />} />
                <Route
                  exact
                  path="/swap/uniswap/:chainId"
                  element={<UniswapSwap />}
                />
                <Route
                  exact
                  path="/swap/uniswap/:tokenA/:tokenB/:chainId"
                  element={<UniswapSwap />}
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
              <Footer />
              <NotificationContainer />
            </BrowserRouter>
          </WagmiConfig>
        </ApolloProvider>
      </Provider>
    </>
  </div>
);
